@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalBottomWrapperText from global;
  padding-bottom: 6px;

  @media (--viewportMedium) {
    padding-bottom: 8px;
  }
}

.finePrint {
  composes: marketplaceModalHelperLink from global;
  padding: 3px 0 5px 0;
  text-align: left;
  color: #1E2915;
  text-align: center;
  font-family: "Karla", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.2px;
  padding-left: 6px;
}

.privacyLink,
.termsLink {
  composes: marketplaceModalHelperLink from global;
  color: #A28930;
  font-family: "Karla", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.2px;

  &:hover {
    /* color: var(--marketplaceColorDark);
    text-decoration: underline; */
    cursor: pointer;
  }
}

.termsInputBox {
  & label {
    justify-content: center;
  }
}