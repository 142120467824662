@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.password {
  composes: marketplaceModalPasswordMargins from global;

  & label {
    color: #1E2915;
    font-family: "Karla", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.2px;
  }

  & input {
    &::placeholder {
      color: #4a2a1882;
      font-family: "Karla", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.305px;
    }
  }
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
  margin-top: 46px;
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.bottomWrapperText {
  composes: marketplaceModalBottomWrapperText from global;
}

.recoveryLink {
  composes: marketplaceModalHelperLink from global;
}

.recoveryLinkInfo {
  composes: marketplaceModalHelperText from global;

  &>span {
    color: #1E2915;
    text-align: center;
    font-family: "Karla", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.2px;

    & a {
      color: #A28930;
      font-family: "Karla", sans-serif;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: -0.2px;
    }
  }
}

.loginButton {
  border-radius: 10px;
  margin-top: 15px;

  & span {
    color: #FCF8F2;
    font-family: "Karla", sans-serif;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.267px;
    text-transform: uppercase;
  }
}

.emailBox {
  & label {
    color: #1E2915;
    font-family: "Karla", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.2px;
  }

  & input {
    &::placeholder {
      color: #4a2a1882;
      font-family: "Karla", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.305px;
    }
  }
}