@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.name {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.firstNameRoot {
  width: calc(50% - 9px);

  & label {
    color: #1E2915;
    font-family: "Karla", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.2px;
  }

  & input {
    &::placeholder {
      color: #4a2a1882;
      font-family: "Karla", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.305px;
    }
  }
}

.lastNameRoot {
  width: calc(50% - 9px);

  & label {
    color: #1E2915;
    font-family: "Karla", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.2px;
  }

  & input {
    &::placeholder {
      color: #4a2a1882;
      font-family: "Karla", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.305px;
    }
  }
}

.password {
  composes: marketplaceModalPasswordMargins from global;

  & label {
    color: #1E2915;
    font-family: "Karla", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.2px;
  }

  & input {
    &::placeholder {
      color: #4a2a1882;
      font-family: "Karla", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.305px;
    }
  }
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
}

.signUpButton {
  border-radius: 10px;
  margin-top: 15px;

  & span {
    color: #FCF8F2;
    text-align: center;
    font-family: "Karla", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.267px;
    text-transform: uppercase;
  }
}

.inputBox {
  & label {
    color: #1E2915;
    font-family: "Karla", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.2px;
  }

  & input {
    &::placeholder {
      color: #4a2a1882;
      font-family: "Karla", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.305px;
    }
  }
}